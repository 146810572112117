<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="关键字">
          <el-input placeholder="请输入" v-model="searchData.keywordValue" class="input-with-select" size="small">
            <el-select v-model="searchData.keywordName" slot="prepend" placeholder="请选择" size="small">
              <el-option v-for="item in searchTypeOrderRemark" :key="item.value" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item label="备注时间">
          <el-date-picker
            style="width:300px;"
            size="small"
            v-model="searchData.timeSlot"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label=" " label-width="50px">
          <el-button type="primary" size="small" icon="el-icon-search" @click="getList()">搜索</el-button>
          <el-button type="info" size="small" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-divider></el-divider>
    <div class="header-btn">
      <!-- <el-button style="margin-bottom:20px" type="primary" icon="el-icon-plus" size="small" @click="$router.push({ name: 'FinanceReportAdd' })"
        >添加账单</el-button
      > -->
      <div></div>
      <el-button
        v-checkbtn="isShowBtn(AUTH_BTN.member_order_remark_export)"
        style="margin-bottom:20px"
        type="primary"
        icon="el-icon-download"
        size="small"
        @click="downloadExcel"
        >导出</el-button
      >
    </div>

    <tp-table
      :isNeedSerialNumber="false"
      :tableData="list"
      :columns="columns"
      :totalNum="total"
      :current-page.sync="currentPage"
      :pageSize.sync="pageSize"
    />
    <!--  -->
    <el-dialog title="添加备注" :visible.sync="dialogVisible" width="700px">
      <el-form ref="formData" :model="formData" label-width="80px">
        <el-form-item label="备注">
          <el-input style="width:400px" type="textarea" rows="3" v-model="formData.content"></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-button type="" @click="dialogVisible = false" size="small">取 消</el-button>
          <el-button type="primary" @click="saveRemark" size="small">保 存</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { authBtnMixin } from '@/mixins/authBtnMixin'
import { searchTypeOrderRemark } from '@/enum/dict.js'
import { getRemarkListAPI, handleAddRemarkAPI, DownloadAPI } from './api'
const columns = [
  {
    label: '客户姓名',
    prop: 'member',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['member']?.['memberName']}</p>
    }
  },
  {
    label: '手机号',
    prop: 'member',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['member']?.['mobile']}</p>
    }
  },
  {
    label: '订单号',
    prop: 'orderNo',
    minWidth: '180',
    customRender(h, row) {
      return <p>{row['orderNo']}</p>
    }
  },
  {
    label: '跟进人',
    prop: 'traceUser',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['traceUser']?.['userName']}</p>
    }
  },
  {
    label: '备注时间',
    prop: 'createTime',
    minWidth: '180',
    customRender(h, row) {
      return <p>{row['createTime']}</p>
    }
  },

  {
    label: '备注',
    prop: 'content',
    minWidth: '240',
    fixed: 'right',
    customRender(h, row) {
      return <p>{row['content']}</p>
    }
  }
]
export default {
  name: 'List',
  mixins: [authBtnMixin],
  data() {
    return {
      dialogVisible: false,
      columns,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      list: [],
      searchData: {
        keywordValue: '',
        keywordName: '',
        timeSlot: []
      },
      formData: {
        content: '',
        orderNo: ''
      },
      searchTypeOrderRemark
    }
  },
  watch: {
    currentPage() {
      this.getList()
    },
    pageSize() {
      this.getList()
    }
  },
  provide() {
    return {
      context: this
    }
  },
  mounted() {
    this.getList()
  },
  activated() {
    this.getList()
  },
  methods: {
    async getList() {
      const { keywordName, keywordValue, timeSlot } = this.searchData
      let params = { page: this.currentPage, pageSize: this.pageSize }
      if (keywordName) params[keywordName] = keywordValue
      if (timeSlot && timeSlot.length > 0) {
        params.leftCreateDate = timeSlot[0]
        params.rightCreateDate = timeSlot[1]
      }
      const res = await getRemarkListAPI(params)
      this.list = res.list || []
      this.total = res.total || 0
    },
    addRemark(orderNo) {
      this.dialogVisible = true
      this.formData = {
        content: '',
        orderNo
      }
    },
    saveRemark() {
      handleAddRemarkAPI(this.formData).then(() => {
        this.$message.success('添加成功')
        this.getList()
        this.dialogVisible = false
      })
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    },
    downloadExcel() {
      const { keywordName, keywordValue, timeSlot } = this.searchData
      let params = { page: this.currentPage, pageSize: this.pageSize }
      if (keywordName) params[keywordName] = keywordValue
      if (timeSlot && timeSlot.length > 0) {
        params.leftCreateDate = timeSlot[0]
        params.rightCreateDate = timeSlot[1]
      }
      DownloadAPI(params)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .header-search {
    .el-form {
      .el-form-item {
        margin-right: 35px;
        .el-input {
          width: 350px;
        }
        .el-date-editor {
          .el-range-separator {
            padding: 0;
          }
          .el-range-input {
            width: 35%;
          }
        }
      }
      .el-select .el-input {
        width: 100px;
      }
      .input-with-select {
        vertical-align: middle;
      }
      .input-with-select .el-input-group__prepend {
        background-color: #fff;
      }
    }
  }
  .el-divider--horizontal {
    margin: 0 0 20px;
  }
  .header-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .tp-table {
    .el-table__fixed-right {
      height: 100% !important;
    }
  }
}
</style>
